import React from "react"
import styled from "styled-components"
import { Typography, IconButton, Grid } from "@material-ui/core"
import { graphql, useStaticQuery } from "gatsby"
import Slider from "react-slick"
import ArrowBack from "@material-ui/icons/ArrowBackIos"
import ArrowForward from "@material-ui/icons/ArrowForwardIos"

// import app components
import { Edges, BackgroundImage } from "components"
import * as theme from "theme"

const Specs = (props) => {
  const { boatId, compareBoats, additionalSpecs, hideOwnSpecs, ...rest } = props

  // Format comparison boats
  const otherBoats = !!compareBoats && compareBoats.map(({ boat }) => boat)

  // Query all boats
  const { nodes } = useBoatsQuery()

  // Get active boat
  const activeBoat = hideOwnSpecs ? additionalSpecs[0] : nodes.find((o) => o.id === boatId)

  // Combine other boats and active boat
  const boats = otherBoats ? [activeBoat, ...otherBoats, ...additionalSpecs] : [activeBoat, ...additionalSpecs]

  // If additional specs exist, we just want to show them
  let visibleBoats = hideOwnSpecs ? additionalSpecs : boats

  // Remove broken boats
  visibleBoats = visibleBoats.filter((o) => !!o)

  const index = hideOwnSpecs ? 0 : visibleBoats.findIndex((o) => o.id === activeBoat.id)

  const settings = {
    dots: false,
    arrows: true,
    autoplay: false,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow: visibleBoats.length < 4 ? visibleBoats.length : 4,
    slidesToScroll: 1,
    infinite: visibleBoats.length > 4,
    initialSlide: index,
    nextArrow: (
      <IconButton>
        <ArrowForward />
      </IconButton>
    ),
    prevArrow: (
      <IconButton>
        <ArrowBack />
      </IconButton>
    ),
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: visibleBoats.length < 3 ? visibleBoats.length : 3
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: visibleBoats.length < 2 ? visibleBoats.length : 2
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  }

  const showOverallLength =
    activeBoat?.boat?.specs?.overallLength.column1 || activeBoat?.boat?.specs?.overallLength.column2
  const showInternalLength =
    activeBoat?.boat?.specs?.internalLength.column1 || activeBoat?.boat?.specs?.internalLength.column2
  const showOverallWidth =
    activeBoat?.boat?.specs?.overallWidth.column1 || activeBoat?.boat?.specs?.overallWidth.column2
  const showInternallWidth =
    activeBoat?.boat?.specs?.internalWidth.column1 || activeBoat?.boat?.specs?.internalWidth.column2
  const showDeadRise = activeBoat?.boat?.specs?.deadRise.column1
  const showTubeDiameter =
    activeBoat?.boat?.specs?.tubeDiameter.column1 || activeBoat?.boat?.specs?.tubeDiameter.column2
  const showNumberOfAirChambers = activeBoat?.boat?.specs?.numberOfAirChambers.column1
  const showMaximumLoad = activeBoat?.boat?.specs?.maximumLoad.column1 || activeBoat?.boat?.specs?.maximumLoad.column2
  const showMaximumProple = activeBoat?.boat?.specs?.maximumPeople.column1
  const showBoatOnlyWeight =
    activeBoat?.boat?.specs?.boatOnlyWeight.column1 || activeBoat?.boat?.specs?.boatOnlyWeight.column2
  const showRecommendedPower = activeBoat?.boat?.specs?.recommendedPower.column1
  const showMaximumPower = activeBoat?.boat?.specs?.maximumPower.column1
  const showEngineShaft = activeBoat?.boat?.specs?.engineShaft.column1
  const showFuelTank = activeBoat?.boat?.specs?.fuelTank.column1
  const showDesignCategory = activeBoat?.boat?.specs?.designCategory.column1
  const showNote = activeBoat?.boat?.specs?.note
  const showPackageSizeForBoatLXWXH =
    activeBoat?.boat?.specs?.packageSizeForBoatLXWXH.column1 || activeBoat?.boat?.specs?.packageSizeForBoatLXWXH.column2

  return (
    <Container {...rest}>
      <StyledEdges size="xl" fullWidth>
        <Grid container>
          <Categories>
            <CategoriesHeader>
              <Typography variant="h4" children={`Specs`} />
            </CategoriesHeader>

            {showOverallLength && <Category children={`Overall Length`} />}
            {showInternalLength && <Category children={`Internal Length`} />}
            {showOverallWidth && <Category children={`Overall Width`} />}
            {showInternallWidth && <Category children={`Internal Width`} />}
            {showDeadRise && <Category children={`Dead Rise`} />}
            {showTubeDiameter && <Category children={`Tube Diameter`} />}
            {showNumberOfAirChambers && <Category children={`Number of air chambers`} />}
            {showMaximumLoad && <Category children={`Maximum Load`} />}
            {showMaximumProple && <Category children={`Maximum People`} />}
            {showBoatOnlyWeight && <Category children={`Boat Only Weight`} />}
            {showRecommendedPower && <Category children={`Recommended Power`} />}
            {showMaximumPower && <Category children={`Maximum Power`} />}
            {showEngineShaft && <Category children={`Engine Shaft`} />}
            {showFuelTank && <Category children={`Fuel Tank`} />}
            {showDesignCategory && <Category children={`Design Category`} />}
            {showNote && <Category children={`Additional Notes`} />}
            {showPackageSizeForBoatLXWXH && <Category children={`Package size for boat ( L x W x H )`} />}
          </Categories>

          <SliderContainer>
            <Slider {...settings}>
              {visibleBoats.map((o, index) => {
                return (
                  <div key={index}>
                    <Item active={!hideOwnSpecs && o.id === activeBoat.id}>
                      <ItemHeader>
                        <ItemHeadline variant="h5" children={o.title || " "} />

                        {o.featuredImage.node && (
                          <ImageContainer>
                            {o.featuredImage.node && (
                              <BackgroundImage backgroundSize="contain" image={o.featuredImage.node} />
                            )}
                          </ImageContainer>
                        )}
                      </ItemHeader>

                      {showOverallLength && (
                        <ItemCategory>
                          {o?.boat?.specs?.overallLength?.column1 && o.boat.specs.overallLength.column1}
                          {o?.boat?.specs?.overallLength?.column1 && o.boat.specs.overallLength.column2 && ` / `}
                          {o?.boat?.specs?.overallLength?.column2 && o.boat.specs.overallLength.column2}
                        </ItemCategory>
                      )}

                      {showInternalLength && (
                        <ItemCategory>
                          {o?.boat?.specs?.internalLength?.column1 && o.boat.specs.internalLength.column1}
                          {o?.boat?.specs?.internalLength?.column1 && o.boat.specs.internalLength.column2 && ` / `}
                          {o?.boat?.specs?.internalLength?.column2 && o.boat.specs.internalLength.column2}
                        </ItemCategory>
                      )}

                      {showOverallWidth && (
                        <ItemCategory>
                          {o?.boat?.specs?.overallWidth?.column1 && o.boat.specs.overallWidth.column1}
                          {o?.boat?.specs?.overallWidth?.column1 && o.boat.specs.overallWidth.column2 && ` / `}
                          {o?.boat?.specs?.overallWidth?.column2 && o.boat.specs.overallWidth.column2}
                        </ItemCategory>
                      )}

                      {showInternallWidth && (
                        <ItemCategory>
                          {o?.boat?.specs?.internalWidth?.column1 && o.boat.specs.internalWidth.column1}
                          {o?.boat?.specs?.internalWidth?.column1 && o.boat.specs.internalWidth.column2 && ` / `}
                          {o?.boat?.specs?.internalWidth?.column2 && o.boat.specs.internalWidth.column2}
                        </ItemCategory>
                      )}

                      {showDeadRise && (
                        <ItemCategory>
                          {o?.boat?.specs?.deadRise?.column1 && o.boat.specs.deadRise.column1}
                        </ItemCategory>
                      )}

                      {showTubeDiameter && (
                        <ItemCategory>
                          {o?.boat?.specs?.tubeDiameter?.column1 && o.boat.specs.tubeDiameter.column1}
                          {o?.boat?.specs?.tubeDiameter?.column1 && o.boat.specs.tubeDiameter.column2 && ` / `}
                          {o?.boat?.specs?.tubeDiameter?.column2 && o.boat.specs.tubeDiameter.column2}
                        </ItemCategory>
                      )}

                      {showNumberOfAirChambers && (
                        <ItemCategory>
                          {o?.boat?.specs?.numberOfAirChambers?.column1 && o.boat.specs.numberOfAirChambers.column1}
                        </ItemCategory>
                      )}

                      {showMaximumLoad && (
                        <ItemCategory>
                          {o?.boat?.specs?.maximumLoad?.column1 && o.boat.specs.maximumLoad.column1}
                          {o?.boat?.specs?.maximumLoad?.column1 && o.boat.specs.maximumLoad.column2 && ` / `}
                          {o?.boat?.specs?.maximumLoad?.column2 && o.boat.specs.maximumLoad.column2}
                        </ItemCategory>
                      )}

                      {showMaximumProple && (
                        <ItemCategory>
                          {o?.boat?.specs?.maximumPeople?.column1 && o.boat.specs.maximumPeople.column1}
                        </ItemCategory>
                      )}

                      {showBoatOnlyWeight && (
                        <ItemCategory>
                          {o?.boat?.specs?.boatOnlyWeight?.column1 && o.boat.specs.boatOnlyWeight.column1}
                          {o?.boat?.specs?.boatOnlyWeight?.column1 && o.boat.specs.boatOnlyWeight.column2 && ` / `}
                          {o?.boat?.specs?.boatOnlyWeight?.column2 && o.boat.specs.boatOnlyWeight.column2}
                        </ItemCategory>
                      )}

                      {showRecommendedPower && (
                        <ItemCategory>
                          {o?.boat?.specs?.recommendedPower?.column1 && o.boat.specs.recommendedPower.column1}
                        </ItemCategory>
                      )}

                      {showMaximumPower && (
                        <ItemCategory>
                          {o?.boat?.specs?.maximumPower?.column1 && o.boat.specs.maximumPower.column1}
                        </ItemCategory>
                      )}

                      {showEngineShaft && (
                        <ItemCategory>
                          {o?.boat?.specs?.engineShaft?.column1 && o.boat.specs.engineShaft.column1}
                        </ItemCategory>
                      )}

                      {showFuelTank && (
                        <ItemCategory>
                          {o?.boat?.specs?.fuelTank?.column1 && o.boat.specs.fuelTank.column1}
                        </ItemCategory>
                      )}

                      {showDesignCategory && (
                        <ItemCategory>
                          {o?.boat?.specs?.designCategory?.column1 && o.boat.specs.designCategory.column1}
                        </ItemCategory>
                      )}
                      {showNote && <ItemCategory>{o?.boat?.specs?.note && o.boat.specs.note}</ItemCategory>}

                      {showPackageSizeForBoatLXWXH && (
                        <ItemCategory>
                          {o?.boat?.specs?.packageSizeForBoatLXWXH?.column1 &&
                            o.boat.specs.packageSizeForBoatLXWXH.column1}
                          {o?.boat?.specs?.packageSizeForBoatLXWXH?.column1 &&
                            o.boat.specs.packageSizeForBoatLXWXH.column2 &&
                            ` / `}
                          {o?.boat?.specs?.packageSizeForBoatLXWXH?.column2 &&
                            o.boat.specs.packageSizeForBoatLXWXH.column2}
                        </ItemCategory>
                      )}
                    </Item>
                  </div>
                )
              })}
            </Slider>
          </SliderContainer>
        </Grid>
      </StyledEdges>
    </Container>
  )
}

const Container = styled.div`
  background: ${theme.colors.background.dark};
  overflow: hidden;
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  height: fit-content;

  .slick-prev {
    left: 20px;
    position: absolute !important;
  }

  .slick-next {
    right: 20px;
    position: absolute !important;
  }
`

const StyledEdges = styled(Edges)`
  position: relative;

  &:before {
    content: "";
    position: absolute;
    right: 100%;
    top: 0;
    height: 100%;
    width: 50vw;
    background: #fff;
  }
`

const Categories = styled.div`
  width: 180px;
  background: #fff;
  border-right: 1px solid #e8e8e8;

  @media (min-width: 768px) {
    width: 250px;
  }
`

const CategoriesHeader = styled.div`
  height: 250px;
  padding: 35px 20px;
`

const Category = styled(Typography)`
  && {
    display: flex;
    align-items: center;
    height: 32px;
    padding: 0 20px;
    border-top: 1px solid ${theme.colors.background.dark};
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
  }
`

const SliderContainer = styled.div`
  width: calc(100% - 180px);

  @media (min-width: 768px) {
    width: calc(100% - 250px);
  }
`

const Item = styled.div`
  position: relative;
  width: 100%;
  text-align: center;
  background: ${(props) => (props.active ? "#f1f1f1" : "transparent")};

  > p:last-child {
    padding-bottom: 100px;
  }
`

const ItemHeader = styled.div`
  height: 250px;
`

const ItemHeadline = styled(Typography)`
  && {
    padding: 0 10px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 105px;
  height: 60px;
  transform: rotate(90deg) translateX(20px);
  margin: 0 auto;
}
`

const ItemCategory = styled(Typography)`
  height: 100% !important;
  && {
    height: 32px;
    padding: 5px 20px;
    border-top: 1px solid #e8e8e8;
    font-size: 14px;
  }
`

const useBoatsQuery = () => {
  const { allWpBoat } = useStaticQuery(
    graphql`
      {
        allWpBoat(sort: { order: DESC, fields: title }) {
          nodes {
            id
            title
            databaseId
            uri
            featuredImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 400, layout: CONSTRAINED)
                  }
                }
              }
            }
            boatTypes {
              nodes {
                name
                databaseId
              }
            }
            boat {
              specs {
                boatOnlyWeight {
                  column1
                  column2
                }
                deadRise {
                  column1
                }
                designCategory {
                  column1
                }
                engineShaft {
                  column1
                }
                fuelTank {
                  column1
                }
                internalLength {
                  column1
                  column2
                }
                internalWidth {
                  column1
                  column2
                }
                maximumLoad {
                  column1
                  column2
                }
                maximumPeople {
                  column1
                }
                maximumPower {
                  column1
                }
                note
                numberOfAirChambers {
                  column1
                }
                overallLength {
                  column1
                  column2
                }
                overallWidth {
                  column1
                  column2
                }
                packageSizeForBoatLXWXH {
                  column1
                  column2
                }
                recommendedPower {
                  column1
                }
                tubeDiameter {
                  column1
                  column2
                }
              }
            }
          }
        }
      }
    `
  )
  return allWpBoat
}

export default Specs
