import React, { useState } from "react"
import styled from "styled-components"
import { Typography, Dialog } from "@material-ui/core"
import useScrollPosition from "@react-hook/window-scroll"

// import app components
import { Edges, Button } from "components"
import Boats from "components/flexibleContent/Boats"
import { isBrowser } from "utils"
import * as theme from "theme"
import { headerHeight, menuBreakpoint } from "components/header/Header"

const BoatMenu = (props) => {
  const {
    renderOverview,
    renderSpecs,
    renderGallery,
    renderDownloads,
    renderColorOptions,
    renderBoatBuilder,
    renderReviews,
    renderFeatures,
    boatTypes,
    boatId
  } = props

  const [modal, setModal] = useState(false)

  const scrollY = useScrollPosition()

  const isSticky = isBrowser && scrollY > window.innerHeight

  return (
    <>
      <Wrapper>
        {isSticky && <div style={{ height: `${headerHeight}px` }} />}

        <Container sticky={isSticky}>
          <Edges size="lg">
            <SecondaryNavigation variant="subtitle1" component="div">
              {renderOverview && <MenuItem href="#overview" children={`Overview`} />}
              {renderGallery && <MenuItem href="#gallery" children={`Gallery`} />}
              {renderSpecs && <MenuItem href="#specs" children={`Specs`} />}
              {renderColorOptions && <MenuItem href="#colors" children={`Colors`} />}
              {renderBoatBuilder && <MenuItem href="#build" children={`Build your boat`} />}
              {renderReviews && <MenuItem href="#reviews" children={`Reviews`} />}
              {renderDownloads && <MenuItem href="#downloads" children={`Downloads`} />}
              {renderFeatures && <MenuItem href="#features" children={`Features`} />}

              <StyledButton
                children="Other Models"
                onClick={() => setModal(true)}
                variant="contained"
                color="secondary"
              />
            </SecondaryNavigation>
          </Edges>
        </Container>
      </Wrapper>

      <Dialog open={modal} onClose={() => setModal(false)} fullWidth maxWidth="md">
        <Boats category={boatTypes?.nodes?.length > 0 && boatTypes.nodes[0]} exclude={boatId} />
      </Dialog>
    </>
  )
}

const Wrapper = styled.div`
  display: none;

  @media (min-width: 1024px) {
    display: block;
  }
`

const Container = styled.div`
  position: ${(props) => (props.sticky ? "fixed" : "relative")};
  top: 0;
  left: 0;
  width: 100%;
  z-index: ${(props) => (props.sticky ? 1000 : 1)};
  background: #fff;
`

const SecondaryNavigation = styled(Typography)`
  height: ${headerHeight - 30}px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (min-width: ${menuBreakpoint}px) {
    height: ${headerHeight}px;
  }
`

const MenuItem = styled.a`
  && {
    margin: 0 20px;
    text-decoration: none;
    color: ${theme.colors.primary};
  }
`

const StyledButton = styled(Button)`
  margin-left: 20px;
`

export default BoatMenu
