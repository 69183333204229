import React from "react"
import styled from "styled-components"
import { Typography, Grid } from "@material-ui/core"

// import app components
import { Edges, Spacer, BackgroundImage } from "components"
import * as theme from "theme"
import { useStore } from "store"

const BoatFeatures = (props) => {
  const { headline, text, items, ...rest } = props

  const [, dispatch] = useStore()

  const showLightBox = (index) => {
    dispatch({
      type: "SET_LIGHTBOX",
      payload: {
        open: true,
        slide: index,
        slides: items,
        options: {
          thumbs: false
        }
      }
    })
  }

  return (
    <Container {...rest}>
      <Spacer pt={{ xs: 40, sm: 60 }} pb={{ xs: 40, sm: 60 }}>
        <Edges size="md">
          {(headline || text) && (
            <Spacer mb={{ xs: 40, sm: 50 }}>
              <Introduction>
                {headline && <Typography variant="h3" children={headline} />}
                {text && (
                  <Spacer mt={20}>
                    <Typography children={text} />
                  </Spacer>
                )}
              </Introduction>
            </Spacer>
          )}

          {items && (
            <Grid container justify="space-between">
              {items.map((o, i) => {
                return (
                  <Item key={i} backgroundSize={o.imageSize} onClick={() => o.imageSize === "cover" && showLightBox(i)}>
                    {o.image && (
                      <BackgroundImage
                        backgroundSize={o.imageSize}
                        image={o.image}
                        animated={o.imageSize === "cover"}
                      />
                    )}

                    <Content className="content">
                      {o.text && (
                        <Spacer pr={{ sm: 20 }}>
                          <Typography children={o.text} />
                        </Spacer>
                      )}
                    </Content>
                  </Item>
                )
              })}
              <Item tabIndex={"-1"} style={{ height: 0, overflow: "hidden", margin: 0 }} />
              <Item tabIndex={"-1"} style={{ height: 0, overflow: "hidden", margin: 0 }} />
            </Grid>
          )}
        </Edges>
      </Spacer>
    </Container>
  )
}

const Container = styled.div`
  background: ${theme.colors.background.light};
`

const Introduction = styled.div`
  text-align: center;
`

const Item = styled.button`
  position: relative;
  width: 100%;
  height: 220px;
  margin-bottom: 10px;
  border: none;
  background: none;

  &:nth-last-child(3) {
    margin-bottom: 0;
  }

  @media (min-width: 640px) {
    width: calc(50% - 20px);
  }

  @media (min-width: 960px) {
    width: calc(50% - 6px);

    &:nth-child(5n + 1),
    &:nth-child(5n + 3) {
      width: calc(30% - 10px);
    }

    &:nth-child(5n + 2) {
      width: calc(40% - 10px);
    }
  }

  &:hover,
  :focus {
    .content {
      background: rgba(0, 0, 0, 0.8);
      opacity: 1;
    }
  }
`

const Content = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  padding: 20px;
  opacity: 0;
  transition: ease all 0.3s;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  cursor: pointer;
`

export default BoatFeatures
