import React from "react"
import styled from "styled-components"
import Slider from "react-slick"
import { IconButton, Typography } from "@material-ui/core"
import ArrowBack from "@material-ui/icons/ArrowBackIos"
import ArrowForward from "@material-ui/icons/ArrowForwardIos"

// import app components
import { BackgroundImage } from "components"
import { useStore } from "store"
import PlayIcon from "icons/play.svg"

const ColorOptions = (props) => {
  const { images } = props

  const [, dispatch] = useStore()

  const settings = {
    dots: false,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: images && images.length > 4,
    nextArrow: (
      <StyledIconButton>
        <ArrowForward />
      </StyledIconButton>
    ),
    prevArrow: (
      <StyledIconButton>
        <ArrowBack />
      </StyledIconButton>
    ),
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: images && images.length < 3 ? images.length : 3
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: images && images.length < 2 ? images.length : 2
        }
      },
      {
        breakpoint: 300,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  }

  const showLightBox = (index) => {
    dispatch({
      type: "SET_LIGHTBOX",
      payload: {
        open: true,
        slide: index,
        slides: images.map((o) => {
          return { ...o, link: { url: o.youtubeUrl } }
        }),
        options: {
          thumbs: false
        }
      }
    })
  }

  return (
    <Container>
      {images && (
        <Slider {...settings}>
          {images.map((o, i) => {
            return (
              <div key={i}>
                <Item onClick={() => showLightBox(i)}>
                  <BackgroundImage backgroundSize="contain" image={o.image} />

                  {o.youtubeUrl && (
                    <PlayIconButton>
                      <PlayIcon />
                    </PlayIconButton>
                  )}
                </Item>

                <Caption variant="h4" children={o.caption || ""} />
              </div>
            )
          })}
        </Slider>
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;

  .slick-prev {
    left: 10px !important;
    position: absolute !important;
  }

  .slick-next {
    right: 10px !important;
    position: absolute !important;
  }
`

const Item = styled.div`
  position: relative;
  height: 250px;
  width: 100%;
  cursor: pointer;
`

const StyledIconButton = styled(IconButton)`
  && {
    svg {
      path {
        fill: #fff;
      }
    }
  }
`

const PlayIconButton = styled(IconButton)`
  && {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    svg {
      width: 120px !important;
      height: 120px !important;

      path {
        fill: #fff;
      }
    }
  }
`

const Caption = styled(Typography)`
  && {
    padding: 20px;
    width: 100%;
    text-align: center;
    font-weight: 400;
    text-transform: none;
  }
`

export default ColorOptions
