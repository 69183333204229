import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"

// import app components
import Button from "components/button"

import Hero from "components/flexibleContent/Hero"
import Text from "components/flexibleContent/Text"
import ColorOptions from "components/flexibleContent/ColorOptions"
import Headline from "components/flexibleContent/Headline"
import Gallery from "components/flexibleContent/Gallery"
import BoatFeatures from "components/boatFeatures"
import Downloads from "components/flexibleContent/Downloads"
import Testimonials from "components/flexibleContent/Testimonials"
import BoatMenu from "components/menu/BoatMenu"
import Specs from "components/specs"

import * as theme from "theme"

export default function BoatTemplate(props) {
  const {
    data: {
      boat: {
        id,
        title,
        boatTypes,
        boat: {
          compareBoats,
          hideOwnSpecs,
          additionalSpecs,
          hero,
          overview,
          description,
          videos,
          gallery,
          colorOptions,
          reviews,
          features,
          optionalFeatures,
          techDownloads
        }
      }
    }
  } = props

  const otherSpecs = additionalSpecs
    ? additionalSpecs.map((o, i) => {
        return {
          id: i,
          title: o.title,
          featuredImage: { node: o.featuredImage },
          boat: {
            specs: o.specs
          }
        }
      })
    : []

  const renderHero = !!hero.image
  const renderOverview = !!overview.image && !!overview.headline
  const renderDescription = !!description.text
  const renderSpecs = true
  const renderGallery = !!gallery.images
  const renderColorOptions = !!colorOptions?.images
  const renderBoatBuilder = false
  const renderReviews = !!reviews?.items && reviews.items.length > 0
  const renderFeatures = !!features.items
  const renderOptionalFeatures = !!optionalFeatures.items
  const renderDownloads = !!techDownloads.items

  return (
    <Container>
      {renderHero && (
        <Hero
          contentAlignment="center"
          description={hero.description}
          topLabel={hero.topLabel}
          button={hero.button}
          headline={title}
          headlineStyle="h1"
          image={hero.image}
          mediaVariant={hero.mediaVariant}
          video={hero.youtubeUrl}
          videoUpload={hero.videoUpload}
        />
      )}

      <BoatMenu
        renderHero={renderHero}
        renderOverview={renderOverview}
        renderSpecs={renderSpecs}
        renderGallery={renderGallery}
        renderColorOptions={renderColorOptions}
        renderBoatBuilder={renderBoatBuilder}
        renderReviews={renderReviews}
        renderDownloads={renderDownloads}
        renderFeatures={renderFeatures}
        boatTypes={boatTypes}
        boatId={id}
      />

      {renderOverview && (
        <Hero
          id="overview"
          contentAlignment="left"
          description={overview.text}
          button={null}
          headline={overview.headline}
          headlineStyle="h2"
          image={overview.image}
        />
      )}

      {renderDescription && <Text id="description" text={description.text} />}

      <StyledHeadline>
        <StyledButton to={`/request-a-quote?model=${title}`} children={`Get a Quote`} variant="outlined" />
        <StyledButton to={`/find-a-dealer`} children={`Find a Dealer`} variant="outlined" />
      </StyledHeadline>

      {videos && <Gallery images={videos.images} />}

      {renderGallery && (
        <>
          <Headline headline={`Gallery`} id="gallery" />
          <Gallery images={gallery.images} />
        </>
      )}

      {renderSpecs && (
        <Specs
          id="specs"
          boatId={id}
          compareBoats={compareBoats}
          hideOwnSpecs={hideOwnSpecs}
          additionalSpecs={otherSpecs}
        />
      )}

      {renderColorOptions && (
        <>
          <Headline headline={`Color Options`} id="colors" />
          <ColorOptions images={colorOptions.images} />
        </>
      )}

      {/* build a boat */}

      {renderReviews && <Testimonials id="reviews" headline={`Reviews`} items={reviews.items} image={reviews.image} />}

      {renderDownloads && <Downloads id="downloads" headline={`Downloads`} items={techDownloads.items} />}

      {renderFeatures && (
        <BoatFeatures
          id="features"
          headline={`Features`}
          text={features.text}
          items={features.items.map(o => {
            return { ...o, imageSize: "cover" }
          })}
        />
      )}

      {renderOptionalFeatures && (
        <BoatFeatures
          id="features-optional"
          headline={`Optional Features`}
          text={optionalFeatures.text}
          items={optionalFeatures.items.map(o => {
            return { ...o, imageSize: "cover" }
          })}
        />
      )}
    </Container>
  )
}

const Container = styled.div``

const StyledHeadline = styled(Headline)`
  background-color: ${theme.colors.secondary};
`

const StyledButton = styled(Button)`
  && {
    margin-bottom: 20px;
    border-color: #fff;
    color: #fff;
    width: 100%;

    @media (min-width: 600px) {
      width: auto;
      margin-bottom: 0;
      margin: 0 20px;
    }
  }
`

export const CollectionQuery = graphql`query BoatTemplate($id: String!) {
  boat: wpBoat(id: {eq: $id}) {
    id
    title
    slug
    uri
    boatTypes {
      nodes {
        name
        databaseId
      }
    }
    boat {
      compareBoats {
        boat {
          ... on WpBoat {
            id
            title
            uri
            featuredImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 400, layout: CONSTRAINED)
                  }
                }
              }
            }
            boatTypes {
              nodes {
                name
                databaseId
              }
            }
            boat {
              specs {
                boatOnlyWeight {
                  column1
                  column2
                }
                deadRise {
                  column1
                }
                designCategory {
                  column1
                }
                engineShaft {
                  column1
                }
                fuelTank {
                  column1
                }
                internalLength {
                  column1
                  column2
                }
                internalWidth {
                  column1
                  column2
                }
                maximumLoad {
                  column1
                  column2
                }
                maximumPeople {
                  column1
                }
                maximumPower {
                  column1
                }
                note
                numberOfAirChambers {
                  column1
                }
                overallLength {
                  column1
                  column2
                }
                overallWidth {
                  column1
                  column2
                }
                packageSizeForBoatLXWXH {
                  column1
                  column2
                }
                recommendedPower {
                  column1
                }
                tubeDiameter {
                  column1
                  column2
                }
                note
              }
            }
          }
        }
      }
      description {
        text
      }
      features {
        items {
          text
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 400, layout: CONSTRAINED)
              }
            }
          }
        }
        text
      }
      optionalFeatures {
        items {
          text
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 400, layout: CONSTRAINED)
              }
            }
          }
        }
        text
      }
      reviews {
        headline
        image {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        items {
          name
          text
          button {
            title
            url
            target
          }
        }
      }
      colorOptions {
        images {
          youtubeUrl
          caption
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 800, layout: CONSTRAINED)
              }
            }
          }
        }
      }
      gallery {
        images {
          youtubeUrl
          caption
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 800, layout: CONSTRAINED)
              }
            }
          }
        }
      }
      videos {
        images {
          youtubeUrl
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 800, layout: CONSTRAINED)
              }
            }
          }
        }
      }
      hero {
        topLabel
        description
        button {
          target
          title
          url
        }
        mediaVariant
        videoUpload {
          localFile {
            publicURL
          }
          mediaItemUrl
        }
        youtubeUrl
        image {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
      overview {
        headline
        text
        image {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
      additionalSpecs {
        title
        featuredImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 400, layout: CONSTRAINED)
            }
          }
        }
        specs {
          boatOnlyWeight {
            column1
            column2
          }
          deadRise {
            column1
          }
          designCategory {
            column1
          }
          engineShaft {
            column1
          }
          fuelTank {
            column1
          }
          internalLength {
            column1
            column2
          }
          internalWidth {
            column1
            column2
          }
          maximumLoad {
            column1
            column2
          }
          maximumPeople {
            column1
          }
          maximumPower {
            column1
          }
          note
          numberOfAirChambers {
            column1
          }
          overallLength {
            column1
            column2
          }
          overallWidth {
            column1
            column2
          }
          packageSizeForBoatLXWXH {
            column1
            column2
          }
          recommendedPower {
            column1
          }
          tubeDiameter {
            column1
            column2
          }
        }
      }
      hideOwnSpecs
      specs {
        boatOnlyWeight {
          column1
          column2
        }
        deadRise {
          column1
        }
        designCategory {
          column1
        }
        engineShaft {
          column1
        }
        fuelTank {
          column1
        }
        internalLength {
          column1
          column2
        }
        internalWidth {
          column1
          column2
        }
        maximumLoad {
          column1
          column2
        }
        maximumPeople {
          column1
        }
        maximumPower {
          column1
        }
        note
        numberOfAirChambers {
          column1
        }
        overallLength {
          column1
          column2
        }
        overallWidth {
          column1
          column2
        }
        packageSizeForBoatLXWXH {
          column1
          column2
        }
        recommendedPower {
          column1
        }
        tubeDiameter {
          column1
          column2
        }
      }
      techDownloads {
        items {
          title
          file {
            mediaItemUrl
          }
        }
      }
    }
  }
}
`
